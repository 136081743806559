import * as React from "react";
import { GatsbyLinkProps } from "gatsby-link";
import { MarkdownRemarkGroupConnection } from "../../graphql-types";
// import { kebabCase } from "lodash";

interface TagsCardProps extends React.HTMLProps<HTMLDivElement> {
  tags: MarkdownRemarkGroupConnection[];
  Link: React.ComponentClass<GatsbyLinkProps<any>>;
  tag?: string;
  path: string;
}

export default (props: TagsCardProps) => {
  return (
    <div>
      {/* todo: tags */}
      {/* <Card.Content>
        <Card.Header>
          Tags
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <List>
          {props.tags.map((tag) => {
            const isActive = tag.fieldValue === props.tag;
            const activeStyle = {
              fontWeight: "700",
            };
            const tagLink = isActive ? `/${props.path}` : `/${props.path}/tags/${kebabCase(tag.fieldValue)}/`;
            return (
              <List.Item as="span" key={tag.fieldValue}>
                <List.Icon name="tag" color={isActive ? "blue" : null} />
                <List.Content style={isActive ? activeStyle : null}>
                  <props.Link to={tagLink}>
                    {tag.fieldValue} ({tag.totalCount})
                  </props.Link>
                </List.Content>
              </List.Item>
            );
          })}
        </List>
      </Card.Content> */}
    </div>
  );
};
