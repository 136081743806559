import * as React from "react";
import { Link } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
// import { Grid, Card, Comment } from "semantic-ui-react";
import { MarkdownRemarkConnection } from "../graphql-types";
import BlogTitle from "../components/BlogTitle";
import TagsCard from "../components/TagsCard/TagsCard";
import BlogPagination from "../components/BlogPagination/BlogPagination";
import { get } from "lodash";
import { withLayout, LayoutProps } from "../components/Layout";
import { MarkdownRemark } from "../graphql-types";

interface NotebookProps extends LayoutProps {
  data: {
    tags: MarkdownRemarkConnection;
    posts: MarkdownRemarkConnection;
  };
  pageContext: {
    tag?: string; // only set into `templates/tags-pages.tsx`
  };
}

const Notebook = (props: NotebookProps) => {
  const tags = props.data.tags.group;
  const posts = props.data.posts.edges;
  const { pathname } = props.location;
  const pageCount = Math.ceil(props.data.posts.totalCount / 10);

  // TODO export posts in a proper component
  const Posts = (
    <div className="ui container">
      {posts.map(({ node }: { node: MarkdownRemark }) => {
        const { frontmatter, timeToRead, fields: { slug }, excerpt } = node;
        // const avatar = frontmatter.author.avatar.children[0] as ImageSharp;
        const cover = get(frontmatter, "image.children.0.fixed", {});

        const extra = (
          <div>
            {/* <Comment>
              <Comment.Content>
                <Comment.Metadata style={{ margin: 0 }}> */}
                  {frontmatter.updatedDate}{/* - {timeToRead} min read*/}
                {/* </Comment.Metadata>
              </Comment.Content>
            </Comment> */}
          </div>
        );

        const descriptionSegment = (
          <div>
            <p>{frontmatter.description}</p>
          </div>
        );

        return (
          <div key={slug}
            as={Link}
            to={slug}
            fluid
            image={cover}
            header={frontmatter.title}
            extra={extra}
            description={descriptionSegment}
          >card</div>
        );
      })}
    </div>
  );

  return (
    <div className="ui container">
      <BlogTitle text={`רשימות`} />
      <div style={{ justifyContent: "space-around" }}>
        <div style={{ maxWidth: 600 }}>
          {Posts}
          <BlogPagination Link={Link} pathname={pathname} pageCount={pageCount} />
        </div>
        <div>
          <TagsCard Link={Link} tags={tags} tag={props.pageContext.tag} path="notes" />
        </div>
      </div>
    </div>
  );
};

export default withLayout(Notebook);

export const pageQuery = graphql`
query PageNotebook {
  # Get tags
  tags: allMarkdownRemark(filter: {frontmatter: {draft: {ne: true}}}) {
    group(field: frontmatter___tags) {
      fieldValue
      totalCount
    }
  }

  # Get posts
  posts: allMarkdownRemark(
    sort: { order: DESC, fields: [frontmatter___updatedDate] },
    filter: {
      frontmatter: { draft: { ne: true } },
      fileAbsolutePath: { regex: "/notes/" }
    },
    limit: 10
  ) {
    totalCount
    edges {
      node {
        excerpt
        timeToRead
        fields {
          slug
        }
        frontmatter {
          title
          description
          updatedDate(formatString: "DD/MM/YY")
          image {
          	children {
              ... on ImageSharp {
                fixed(width: 700, height: 300) {
                  src
                  srcSet
                }
              }
            }
          }
          author {
            id
            avatar {
              children {
                ... on ImageSharp {
                  fixed(width: 35, height: 35) {
                    src
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
