import * as React from "react";

export interface BlogTitleProps {
  text?: string;
}

export default ({ text }: BlogTitleProps) => {
  return (
   <div></div>
  );
};
